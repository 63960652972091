<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-radio-group
      v-model="value"
      row
      :style="style"
    >
      <v-radio
        :label="this.getLabel(1, this.lang('ChecklistBuilder.misc.yes'))"
        value="1"
        :disabled="disabled"
        hide-details
      />
      <v-radio
        :label="this.getLabel(2, this.lang('ChecklistBuilder.misc.no'))"
        value="2"
        :disabled="disabled"
        hide-details
      />
    </v-radio-group>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeRadio',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = 'width: 100%;'
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style += ` outline: 1px solid ${outlineColor};`
      }
      return style
    },
    disabled() {
      return this.state.disabled
    },
    value: {
      get() {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        const value = builder.getCellValue(index, this.cell, this.clone, '0')
        return value
      },
      set(value) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        const previousValue = builder.getCellValue(index, this.cell, this.clone, '0')
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.hasOwnProperty('value')) {
            return
          }
          this.value(result.value)
        })
      }
    },
    getLabel(index, fallback) {
      return this.cell.settings[`radio_btn_label_${index}`] || fallback
    }
  }
}
</script>

<style>
  .checklist-viewer .cell_type_radio label:not(:first-child) {
    margin-left: 15px;
  }
  .checklist-viewer .checklist-content .cell_type_radio .v-input--radio-group {
    margin-top: 0px;
    padding: 2px 0px;
    display: flex;
    justify-content: center;
  }
  .checklist-viewer .checklist-content .cell_type_radio .v-input--radio-group__input {
    justify-content: center;
  }
  .checklist-viewer .checklist-content .cell_type_radio .v-input--radio-group label {
    margin-left: 0px;
  }
  .checklist-viewer .checklist-content .cell_type_radio .v-messages {
    display: none;
  }
</style>
